export const mainMenuItems = [
  {
    name: 'Home',
    stateName: 'main.home',
  },
  {
    name: 'My Learning',
    stateName: 'main.learning',
    type: 'custom',
  },
  {
    name: 'Catalog',
    stateName: 'main.fullCatalog',
    stateParams: { type: 'course' },
    data: { availableFor: ['regularUser', 'admin'] },
  },
  {
    name: 'Calendar',
    stateName: 'main.calendar',
  },
  {
    name: 'Search',
    stateName: 'main.search',
  },
  {
    name: 'Discussions',
    stateName: 'main.search',
    stateParams: { type: 'discussion' },
    data: { availableFor: ['regularUser', 'admin'] },
  },
  {
    name: 'Admin Site',
    href: '{{basePath}}admin',
    type: 'custom',
    data: { availableFor: ['admin'] },
  },
];
